/** @jsx jsx */
import { graphql } from "gatsby";
import EventTitle from "gatsby-theme-event-pages/src/components/EventTitle";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetEventHeader" }>;
  data: PageQuery["event"];
}

const EventHeader: React.FC<Props> = ({
  data,
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  if (!data) {
    return null;
  }

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <div sx={{ textAlign: "center" }}>
        <EventTitle
          endAt={data.endAt}
          level={1}
          startAt={data.startAt}
          timeZone={data.timeZone}
          title={data.title}
          type={data.type}
        />
      </div>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetEventHeader on WidgetEventHeader {
    id
    __typename
  }
`;

export default memo(EventHeader);
